import { render, staticRenderFns } from "./PlatformAds.vue?vue&type=template&id=718b15be&scoped=true&"
import script from "./PlatformAds.vue?vue&type=script&lang=js&"
export * from "./PlatformAds.vue?vue&type=script&lang=js&"
import style0 from "./PlatformAds.vue?vue&type=style&index=0&id=718b15be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718b15be",
  null
  
)

export default component.exports