<template>
    <div class="tab-content">
        <div class="tab-header flexed">
            <div class="search-wrapper">
                <v-text-field
                    class="styled-field"
                    label="Search"
                    clearable
                    placeholder="Enter terms..."
                    @input="handleSearch" />
            </div>
        </div>
        <div class="table-wrapper">
            <platform-ads
                v-for="(platform, index) in filteredPlatforms"
                :key="platform"
                :search="search"
                :platform="platform"
                :index="index" />
        </div>
    </div>
</template>

<script>
import debounce from 'debounce';
import { mapState } from 'vuex';
import PlatformAds from './PlatformAds';
import { cloneDeep } from 'lodash';

export default {
    name: 'PlatformAdsTab',
    components: {
        PlatformAds,
    },
    props: {
        platforms: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            search: '',
            initialSelectedAds: [],
        };
    },
    computed: {
        ...mapState({
            selectedCampaigns: (state) => state.playbookManagement.selectedCampaigns,
            selectedAdSets: (state) => state.playbookManagement.selectedAdSets,
            selectedAds: (state) => state.playbookManagement.selectedAds,
            dismissedAlerts: (state) => state.settings.dismissedAlerts
        }),
        filteredPlatforms() {
            const selectedPlatforms = this.platforms.filter(platform => (
                this.selectedCampaigns[platform].length ||
                this.selectedAdSets[platform].length ||
                this.initialSelectedAds?.[platform]?.length
            ));

            return selectedPlatforms.length ? selectedPlatforms : this.platforms;
        },
    },
    created() {
        // If the reports loads with ads selected this means
        // the user wants to target these but we don't want to
        // remove the platform when ad selections are made
        this.initialSelectedAds = cloneDeep(this.selectedAds);
    },
    methods: {
        handleSearch: debounce(function(input) {
            this.search = input;
        }, 400),
    }
};
</script>
